<template>
  <div>
    <div style="margin-bottom: 16px;">
      <a-page-header title="告警事件" :ghost="false" :sub-title="detail.name">
        <template slot="tags">
          <boolean-status-badge false-name="告警" true-name="恢复" :value="detail.recovered"></boolean-status-badge>
        </template>
        <template v-if="permission.update" slot="extra">
          <a @click="$refs.updateForm.show(detail.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <update-form ref="updateForm" @ok="fetch"></update-form>
        </template>

        <a-descriptions :column="{ xs: 1, sm: 2, xl: 3, xxl: 4 }">
          <a-descriptions-item label="事件源">
            <a-tooltip v-if="detail.source" :title="detail.source.name">
              <a-tag color="blue">{{ $t(`source_type.${detail.source_type}`) }}</a-tag>
              <router-link :to="{ name: routerName, params: { id: detail.source.id } }">
                <span>{{ detail.source.name }}</span>
              </router-link>
            </a-tooltip>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="严重性">
            <severity-tag :severity="detail.severity"></severity-tag>
          </a-descriptions-item>
          <a-descriptions-item label="触发时间">{{ detail.triggered_at }}</a-descriptions-item>
          <a-descriptions-item label="恢复时间">{{ detail.recovered_at }}</a-descriptions-item>
          <a-descriptions-item label="所属项目">{{ detail.project ? detail.project.name : '-' }}</a-descriptions-item>
          <a-descriptions-item label="告警类型">
            <a-tooltip v-if="detail.category" placement="topLeft" :title="detail.category.name">{{ detail.category.name }}</a-tooltip>
            <span v-else>-</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-page-header>
    </div>

    <a-card :active-tab-key="activeTabKey" :bordered="false" :tab-list="tabList" @tabChange="key => { this.activeTabKey = key }">
      <history-tab v-show="activeTabKey === 'history'" :alert-event-id="$route.params.id"></history-tab>
    </a-card>
  </div>
</template>

<script>
import { getAlertEvent } from '@/api/alert-event'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import SeverityTag from '@/components/tag/SeverityTag'
import { hasPermission, getSourceRouterName } from '@/utils'
import UpdateForm from '../modules/UpdateForm'
import HistoryTab from './modules/HistoryTab'

export default {
  name: 'AlertEventDetail',
  components: {
    BooleanStatusBadge,
    HistoryTab,
    SeverityTag,
    UpdateForm
  },
  data () {
    return {
      detail: {
        source_type: 'unknown',
        category: {}
      },
      activeTabKey: 'history',
      tabList: [{ key: 'history', tab: '监控数据' }]
    }
  },
  computed: {
    permission () {
      return {
        update: hasPermission('event.update')
      }
    },
    routerName () {
      return getSourceRouterName(this.detail.source_type)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getAlertEvent(this.$route.params.id).then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>
