<template>
  <div :id="chartId"></div>
</template>

<script>
import { Chart } from '@antv/g2'
import { humanValue } from '@/utils'

export default {
  name: 'LineChart',
  props: {
    chartId: { type: String, required: true },
    history: { type: Array, default: () => [] }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.chart = new Chart({
      container: this.chartId,
      autoFit: true,
      appendPadding: [8, 0, 0, 0]
    })
    this.chart.scale({
      timestamp: { type: 'time', mask: 'YYYY-MM-DD HH:mm' },
      value: {
        min: 0,
        nice: true,
        alias: '值',
        formatter: (value) => humanValue(value)
      }
    })
    this.chart.line().position('timestamp*value')
    this.chart.area().position('timestamp*value')
    if (this.history.length !== 0) {
      let max = this.history[0]
      let min = this.history[0]
      for (const data of this.history) {
        if (data.value > max.value) max = data
        if (data.value < min.value) min = data
      }
      this.chart.annotation().dataMarker({
        direction: 'downward',
        top: true,
        position: [max.timestamp, max.value],
        text: { content: `峰值: ${humanValue(max.value)}` }
      })
      if (max.timestamp !== min.timestamp) {
        this.chart.annotation().dataMarker({
          top: true,
          position: [min.timestamp, min.value],
          text: { content: `谷值: ${humanValue(min.value)}` }
        })
      }
    }
    this.chart.data(this.history)
    this.chart.render()
  }
}
</script>
