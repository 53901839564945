import request from '@/utils/request'

const urlPrefix = '/alert-categories'

export function getAlertCategory (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateAlertCategory (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteAlertCategory (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getAlertCategoryList (params) {
  return request.get(urlPrefix, { params })
}

export function createAlertCategory (data) {
  return request.post(urlPrefix, data)
}
