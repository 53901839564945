<template>
  <a-row :gutter="16">
    <a-col v-for="snapshot in snapshots" :key="snapshot.id" :xs="24" :xl="12" :xxl="8" style="margin-bottom: 16px;">
      <a-card :head-style="{ border: 'none' }" :hoverable="true" size="small" :title="title(snapshot.name, snapshot.unit)">
        <line-chart :chart-id="snapshot.id" :history="snapshot.history" style="height: 320px;"></line-chart>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { getAlertEventSnapshotList } from '@/api/alert-event'
import LineChart from './LineChart'

export default {
  name: 'HistoryTab',
  components: { LineChart },
  props: {
    alertEventId: { type: String, required: true }
  },
  data () {
    return {
      snapshots: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getAlertEventSnapshotList(this.alertEventId).then(res => {
        this.snapshots = res.data.data
      })
    },
    title (name, unit) {
      if (unit) return `${name} (${unit})`
      else return name
    }
  }
}
</script>
